<template>
  <div :class="`modal ${isActive ? 'is-active' : ''}`">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Invite {{subjectName}}</p>
        <button class="delete" aria-label="close" @click="hideInviteForm" :disabled="isLoading"></button>
      </header>
      <section class="modal-card-body">
        <div class="emails-field is-flex is-flex-wrap-wrap">
          <div class="p-1 m-1 tag" v-for="email in inviteEmails" :key="email" v-show="!onDealerPage">
            {{ email }}
            <a class="ml-2" @click="removeEmail(email)">
              <b-icon pack="fas" icon="times" size="is-small"></b-icon>
            </a>
          </div>
          <input
            placeholder="Input email here..."
            type="email"
            class="no-border m-1 is-flex-grow-1"
            v-model.trim="inviteEmail"
            @input="onEmailInput"
            @keydown.enter.prevent="onEnterEmail"
            @keydown.,.prevent="onEnterEmail"
            @keydown.delete="onRemoveLastEmail">
          <span class="icon p-1 m-1">
            <img
              v-show="dealerEmailValid"
              class="icon-check"
              src="@assets/icons/check.svg"
              alt="is-success">
          </span>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="is-flex is-flex-direction-column">
          <div class="help is-danger mb-2">{{ error }}</div>
          <div class="is-horizontal">
            <button class="button is-success" @click="inviteAction" :disabled="buttonDisabled">Invite</button>
            <button class="button" @click="hideInviteForm" :disabled="isLoading">Cancel</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import { inviteAdministrator } from '@api/addy-plus/account-administrators'

const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    administrators: {
      type: Array,
      default: () => [],
    },
    successInviteCallback: {
      type: Function,
    },
    hideForm: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
      inviteEmail: '',
      inviteEmails: [],
      dealerEmailValid: false,
      error: '',
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    isValidEmail() {
      return VALID_EMAIL_REGEX.test(this.inviteEmail)
    },
    isExistingAdmin() {
      return this.administrators.map((admin) => admin.email).includes(this.inviteEmail)
    },
    isInvited() {
      return this.inviteEmails.includes(this.inviteEmail)
    },
    buttonDisabled() {
      return this.error || this.inviteEmails.length === 0 || this.isLoading
    },
    onDealerPage() {
      return this.$route.name === 'addyPlusAccountSettingsDealers'
    },
    subjectName() {
      return this.onDealerPage ? 'Dealer\'s Primary Contact' : 'User'
    },
  },
  methods: {
    inviteAction() {
      const payload = { emails: this.inviteEmails, type: this.onDealerPage ? 'dealer' : 'user' }
      this.isLoading = true
      inviteAdministrator(this.accountId, payload).then((result) => {
        this.isLoading = false
        if (!result.success) return
        this.successInviteCallback()
        this.hideInviteForm()
      })
    },
    onEnterEmail() {
      if (!this.isValidEmail) return
      if (this.isExistingAdmin) this.error = 'User with that email already exists'
      else if (this.isInvited) this.error = 'User was already invited'
      else {
        if (this.onDealerPage) {
          // This functionality is on-hold for now. This is where the valid dealer email check would go.
        } else {
          this.inviteEmails.push(this.inviteEmail)
          this.inviteEmail = ''
          this.resetError()
        }
      }
    },
    onRemoveLastEmail() {
      if (this.inviteEmail.length === 0 && this.inviteEmails.length > 0) {
        this.removeEmail(this.inviteEmails[-1])
      }
    },
    removeEmail(email) {
      this.inviteEmails.splice(this.inviteEmails.indexOf(email), 1)
    },
    hideInviteForm() {
      this.inviteEmail = ''
      this.inviteEmails = []
      this.dealerEmailValid = false
      this.hideForm()
    },
    onEmailInput() {
      if (this.onDealerPage) {
        this.dealerEmailValid = false
        // prevent duplicity
        this.inviteEmails = []
      }
      if (this.inviteEmail === '') return this.resetError()
      this.error = !this.isValidEmail ? 'Invalid email format' : 'Press Enter to add email'
    },
    resetError() {
      this.error = ''
    }
  },
}
</script>
<style lang="scss" scoped>
.no-border {
  border: unset;
}
.emails-field div {
  background: var(--addy-blue-light-1);
  color: #fff;
  border-radius: 5px;
  ::v-deep .icon i {
    color: #fff;
  }
}
</style>
