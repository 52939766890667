<template>
  <div class="addy-plus-account-settings-administrators">
    <Breadcrumb/>
    <div class="is-flex is-align-items-center mb-5">
      <div class="title m-0 is-flex-grow-1">{{fieldsData ? fieldsData.accountName : 'Account'}}</div>
      <button @click="showInviteForm" v-show="!onDealerPage" class="button is-success">Invite User</button>
    </div>
    <b-table :data="administrators" hoverable>
      <b-table-column field="id" :label="firstColumnLabel" v-slot="props">
        <a @click="toAdministratorPage(props.row.id)" class="has-text-blue">{{ firstColumnValue(props.row) }}</a>
      </b-table-column>
      <b-table-column field="email" :label="secondColumnLabel" v-slot="props">{{ props.row.email }}</b-table-column>
      <b-table-column field="invitationStatus" label="Invitation Status" v-slot="props">{{ props.row.invitationStatus }}</b-table-column>
      <b-table-column field="active" label="Active" v-slot="props">{{ props.row.active }}</b-table-column>
      <b-table-column field="action" label="Actions" v-slot="props">
        <button v-if="props.row.invitationStatus === 'expired' && !onDealerPage" @click="resendInviteAction(props.row.id)" class="button is-success is-small resend">Resend Invite</button>
        <button v-else-if="props.row.active" @click="updateAdmin(props.row.id, false)" class="button is-danger is-small">Deactivate</button>
        <button v-else @click="updateAdmin(props.row.id, true)" class="button is-success is-small">Activate</button>
        <button @click="deleteAdmin(props.row.id)" class="button is-info is-small ml-2">Remove</button>
      </b-table-column>
    </b-table>
    <InviteFormModal
      :is-active="isInviteFormActive"
      :administrators="administrators"
      :success-invite-callback="successInviteCallback"
      :hide-form="hideInviteForm" />
    <Flash title="Congrats" :content="flashContent" :show="!!flashContent" :hideFlash="hideFlash"></Flash>
  </div>
</template>
<script>
import InviteFormModal from './InviteFormModal.vue'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import { fetchAccount } from '@api/addy-plus/accounts'
import {
  fetchAllAdministrators,
  updateAdministrator,
  resendAdministratorInvite,
  deleteAdministrator,
} from '@api/addy-plus/account-administrators'
import Flash from '@components/modal/slide-down-flash.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'


export default {
  title: 'Account Administrators',
  mixins: [pageTitleMixin],
  components: {
    InviteFormModal,
    Flash,
    Breadcrumb,
  },
  data() {
    return {
      fieldsData: null,
      administrators: [],
      isInviteFormActive: false,
      flashContent: '',
      timer: null,
    }
  },
  computed: {
    onDealerPage() {
      return this.$route.name === 'addyPlusAccountSettingsDealers'
    },
    accountId() {
      return this.$route.params.accountId
    },
    firstColumnLabel() {
      return this.onDealerPage ? 'Dealer Name' : 'User ID'
    },
    secondColumnLabel() {
      return this.onDealerPage ? 'Primary Contact' : 'Email'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      fetchAccount(this.accountId).then((result) => {
        if (!result.success) return
        this.fieldsData = result.data
      })
      this.fetchAdministrators()
    },
    fetchAdministrators() {
      fetchAllAdministrators(this.accountId, this.onDealerPage ? 'account' : 'user').then((result) => {
        if (!result.success) return
        this.administrators = result.data
      })
    },
    updateAdmin(adminId, activated) {
      const payload = { activated: activated }
      updateAdministrator(this.accountId, adminId, payload).then((result) => {
        if (result.success) {
          this.showFlashWithContent(result.data.message)
          const index = this.administrators.findIndex((admin) => admin.id === result.data.admin.id)
          this.administrators.splice(index, 1, result.data.admin)
        }
      })
    },
    resendInviteAction(adminId) {
      resendAdministratorInvite(this.accountId, adminId).then((result) => {
        if (!result.success) return
        this.showFlashWithContent(result.data.message)
        const index = this.administrators.findIndex((admin) => admin.id === result.data.admin.id)
        this.administrators.splice(index, 1, result.data.admin)
      })
    },
    deleteAdmin(adminId) {
      deleteAdministrator(this.accountId, adminId).then((result) => {
        if (!result.success) return
        this.showFlashWithContent(result.data.message)
        const index = this.administrators.findIndex((admin) => admin.id === adminId)
        this.administrators.splice(index, 1)
      })
    },
    showInviteForm() {
      this.isInviteFormActive = true
    },
    hideInviteForm() {
      this.isInviteFormActive = false
    },
    successInviteCallback() {
      this.fetchAdministrators()
      this.showFlashWithContent('Successfully send email invitations')
    },
    showFlashWithContent(content) {
      this.flashContent = content
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
    hideFlash() {
      this.flashContent = ''
    },
    toAdministratorPage(adminId) {
      this.$router.push(`${this.$router.currentRoute.path}/${adminId}`)
    },
    firstColumnValue(row) {
      return this.onDealerPage ? row.accountName : row.id
    },
  },
}
</script>
<style lang="scss" scoped>
.addy-plus-account-settings-administrators {
  font-size: 16px;
  .b-table {
    ::v-deep td {
      vertical-align: middle;
      &[data-label="Actions"] {
        display: flex;
        justify-content: space-between;
      }
    }
    .button {
      font-size: 14px;
      &.resend {
        background-color: #3488ce;
        color: #fff;
      }
      &.is-success {
        background-color: #3ec487;
      }
      &:not(:last-of-type) {
        width: 100px;
      }
    }
  }
}
</style>
